<template>
  <div class="fxyy">
    <div v-if="flag == 1">
      <el-row :gutter="3" style="padding: 0 0 0 10px">
        <el-col :span="3">
          <el-input
            v-model.trim="name"
            :placeholder="$t('AppManage.ThreatDefinition.RiskApp.Placeholder')"
            size="small"
            @keyup.enter.native="search"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <div class="btn">
        <el-button-group>
          <el-button type="primary" @click="addNew" size="small">{{
            $t("public.New")
          }}</el-button>
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delArr(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </div>
      <div style="position: relative">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          ref="tableData"
          :data="tableData"
          :row-key="getRowKeys"
          lazy
          size="mini"
          stripe
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%; margin-left: 10px; padding-right: 20px"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          @selection-change="delobj"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" align="center" width="60"> </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('AppManage.ThreatDefinition.RiskApp.Name')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="os"
            :label="$t('public.SystemCategory')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="content.feature"
            :label="$t('AppManage.ThreatDefinition.RiskApp.Feature')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="updateTime"
            :label="$t('public.ModificationTime')"
            width="180"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img
                  @click="edit(scope.row)"
                  src="@/assets/icon_write.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Editor')"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="del(scope.row.id)"
                  src="@/assets/icon_del.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('public.Delete')"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          style="margin-left: 10px; margin-right: 10px"
          :page="currentPage"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </div>
    <!-- 新增 -->
    <div v-else-if="flag == 2" style="margin-top: 5px">
      <el-form
        @submit.native.prevent
        :model="form"
        :rules="rules"
        ref="form"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('AppManage.ThreatDefinition.RiskApp.Name') + '：'"
          prop="name"
          style="margin-bottom: 20px"
        >
          <el-input
            type="text"
            v-model="form.name"
            clearable
            size="small"
            :placeholder="$t('AppManage.ThreatDefinition.RiskApp.Placeholder')"
            class="el-input1"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.ThreatDefinition.RiskApp.Feature') + '：'"
          prop="content.feature"
        >
          <el-input
            type="text"
            v-model="form.content.feature"
            clearable
            size="small"
            :placeholder="$t('AppManage.ThreatDefinition.RiskApp.FeaturePlaceholder')"
            class="el-input1"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.SystemCategory') + '：'" prop="os">
          <el-radio v-model="form.os" label="Android">Android</el-radio>
        </el-form-item>
        <el-form-item>{{ $t("AppManage.ThreatDefinition.RiskApp.Desc") }}</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add('form')" size="mini" class="save">{{
          $t("public.Save")
        }}</el-button>
      </div>
    </div>
    <!-- 编辑 -->
    <div v-else style="margin-top: 5px">
      <el-form
        @submit.native.prevent
        :model="form"
        :rules="rules"
        ref="form"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('AppManage.ThreatDefinition.RiskApp.Name') + '：'"
          prop="name"
        >
          <el-input
            type="text"
            v-model="form.name"
            clearable
            size="small"
            :placeholder="$t('AppManage.ThreatDefinition.RiskApp.Placeholder')"
            class="el-input1"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('AppManage.ThreatDefinition.RiskApp.Feature') + '：'"
          prop="content.feature"
        >
          <el-input
            type="text"
            v-model="form.content.feature"
            clearable
            size="small"
            :placeholder="$t('AppManage.ThreatDefinition.RiskApp.FeaturePlaceholder')"
            class="el-input1"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.SystemCategory') + '：'" prop="os">
          <el-radio v-model="form.os" label="Android">Android</el-radio>
        </el-form-item>
        <el-form-item>{{ $t("AppManage.ThreatDefinition.RiskApp.Desc") }}</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('form')" size="mini" class="save">{{
          $t("public.Save")
        }}</el-button>
      </div>
    </div>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { pagination, dialogInfo },

  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      seccess: "",
      id: "",
      name: "",
      Successdialog: false, //控制弹出
      orderColume: "updateTime",
      orderRule: "DESC",
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,
      pageSizes: [10, 20, 30, 40],
      delarr: [],
      tableData: [],
      multipleSelection: [],
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("AppManage.ThreatDefinition.RiskApp.Placeholder"),
          },
          {
            min: 1,
            max: 30,
            message: this.$t("AppManage.ThreatDefinition.NameLength"),
            trigger: "blur",
          },
        ],
        content: {
          feature: [
            {
              required: true,
              message: this.$t("AppManage.ThreatDefinition.RiskApp.FeaturePlaceholder"),
              trigger: "blur",
            },
            {
              min: 1,
              max: 30,
              message: this.$t("AppManage.ThreatDefinition.NameLength"),
              trigger: "blur",
            },
          ],
        },
      },
      form: {
        name: "",
        os: "Android", //系统类别, iOS, Android
        content: {
          feature: "", //风险特征
        },
      },
    };
  },

  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.getDataList();
  },

  methods: {
    getRowKeys(row) {
      return row.id;
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    //监听排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.search();
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: "danger-app",
        manage: 1,
        name: this.name,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      const res = await this.$axios.post(
        "/httpServe/monitorExDangerRule/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 查询
    async search() {
      if (this.name.length > 0) {
        this.currentPage = 1;
      }
      this.getDataList();
    },
    addNew() {
      this.flag = 2;
    },
    // 新增
    async add(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          var param = {
            name: this.form.name,
            level: "0",
            type: "danger-app",
            os: this.form.os,
            content: {
              feature: this.form.content.feature,
            },
          };
          const res = await this.$axios.post(
            "httpServe/monitorExDangerRule/save",
            param,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("AppManage.ThreatDefinition.RiskApp.SameName"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            this.flag = 1;
            this.name = "";
            this.getDataList();
            this.form = {
              name: "",
              os: "Android",
              content: {
                feature: "",
              },
            };
          }
        } else {
          console.log("error");
          return false;
        }
      });
    },
    // 回显
    async edit(info) {
      this.flag = 3;
      var parma = {
        id: info.id,
      };
      const res = await this.$axios.post(
        "/httpServe/monitorExFrameRule/selectOne",
        parma,
        true
      );
      this.id = res.data.id;
      this.form.name = res.data.name;
      this.form.content.feature = res.data.content.feature;
    },
    // 编辑后保存
    save(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          var parma = {
            id: this.id,
            name: this.form.name,
            level: "0",
            type: "frame",
            os: this.form.os,
            content: {
              feature: this.form.content.feature,
            },
          };
          const res = await this.$axios.post(
            "/httpServe/monitorExFrameRule/update",
            parma,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t("AppManage.ThreatDefinition.RiskApp.SameName"),
              type: "error",
              offset: 100,
            });
          } else {
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            this.flag = 1;
            this.name = "";
            this.getDataList();
            this.form = {
              name: "",
              os: "Android",
              content: {
                feature: "",
              },
            };
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 批量删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var param = { ids: ids };
      this.$confirm(
        this.$t("AppManage.ThreatDefinition.RiskApp.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/monitorExDangerRule/delete",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    del(id) {
      this.$confirm(
        this.$t("AppManage.ThreatDefinition.RiskApp.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var parma = { ids: [id] };
          const res = await this.$axios.post(
            "/httpServe/monitorExDangerRule/delete",
            parma,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

.zrgj {
  width: 100%;
  height: 100%;
}

.el-input1 {
  width: 250px;
}

.el-button + .el-button {
  margin-left: 0px;
}
// 处理按钮
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 1px;
}
.save {
  margin: 0px 0 0 180px;
}

.el-form {
  margin-top: 10px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;
  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-table__body {
  overflow: auto;
}

.el-form-item__content {
  width: 76%;
  display: flex;
  margin: 0 !important;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>