<template>
  <div>
    <!-- <div class="title">
      <h4 class="breadtitle">威胁定义</h4>
    </div> -->
    <!-- <navigation /> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.InjectionFlaws')" name="1">
        <zrgj :key="timer1"></zrgj>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.DomainNameFraud')" name="2">
        <ymqz :key="timer2"></ymqz>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.ProgramPlug')" name="3">
        <cxwz :key="timer3"></cxwz>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.ApplicationCrack')" name="4">
        <yypj :key="timer4"></yypj>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.HTTPSHijacks')" name="5">
        <https :key="timer5"></https>
      </el-tab-pane>
      <!-- <el-tab-pane :label="$t('AppManage.ThreatDefinition.HighFrequencyBehavior')" name="6"> -->
      <!-- <gycxw :key="timer6"></gycxw> -->
      <!-- </el-tab-pane> -->
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.FrameSoft')" name="7">
        <kjrj :key="timer7"></kjrj>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.RiskApplication')" name="8">
        <fxyy :key="timer8"></fxyy>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.ThreatsIP')" name="9">
        <riskIP :key="timer9"></riskIP>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ThreatDefinition.Yphone')" name="10">
        <yMobile :key="timer10"></yMobile>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import navigation from '@/components/hearder'
import zrgj from './ThreatDefinition/zrgj.vue'
import ymqz from './ThreatDefinition/ymqz.vue'
import cxwz from './ThreatDefinition/cxwz.vue'
import yypj from './ThreatDefinition/yypj.vue'
import https from './ThreatDefinition/httpsjc.vue'
import gycxw from './ThreatDefinition/gpycxw.vue'
import kjrj from './ThreatDefinition/kjrj.vue'
import fxyy from './ThreatDefinition/fxyy.vue'
import riskIP from './ThreatDefinition/riskIP.vue'//新增tab页“风险IP”，放在风险应用后
import yMobile from './ThreatDefinition/yMobile.vue'
export default {
  components: { zrgj, ymqz, cxwz, yypj, https, gycxw, kjrj, fxyy,riskIP, navigation,yMobile },
  data() {
    return {
      activeName: '1',
      timer1: '',
      timer2: '',
      timer3: '',
      timer4: '',
      timer5: '',
      timer6: '',
      timer7: '',
      timer8: '',
      timer9: '',
      timer10:''
    }
  },
  methods: {
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
      } else if (name == 3) {
        this.timer3 = new Date().getTime()
      } else if (name == 4) {
        this.timer4 = new Date().getTime()
      } else if (name == 5) {
        this.timer5 = new Date().getTime()
      } else if (name == 6) {
        this.timer6 = new Date().getTime()
      } else if (name == 7) {
        this.timer7 = new Date().getTime()
      } else if (name == 8) {
        this.timer8 = new Date().getTime()
      }else if (name == 9) {
        this.timer9 = new Date().getTime()
      }else if (name == 10) {
        this.timer10 = new Date().getTime()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>