<template>
  <div style="position: relative">
      <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" ref="tableData" :data="tableData" :row-key="getRowKeys" lazy size="mini" stripe class="tableClass" tooltip-effect="dark" style="width: 100%; margin-left: 10px;padding-right:20px" :default-sort="{ prop: 'updateTime', order: 'descending' }"  @sort-change="handleSortChange">
          <el-table-column prop="name" :label="$t('AppManage.ThreatDefinition.RiskIP.Name')" width="90" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column :label="$t('AppManage.ThreatDefinition.RiskIP.Configuration')" width="550" :show-overflow-tooltip="true">
            <template v-slot="{row}">
              <!-- row.content.number  v-if="row.type=='danger_ip'" -->
              <!-- 同IP同用户连续登录错误( )次后，判定为风险IP并锁定 -->
              <span  v-if="row.name==$t('AppManage.ThreatDefinition.RiskIP.BlastingIP')">{{$t('AppManage.ThreatDefinition.RiskIP.Desc1')}}{{row.content.number}}{{$t('AppManage.ThreatDefinition.RiskIP.Desc2')}}</span>
              <!-- 同IP( )小时内发生()次威胁风险后，判定为风险IP并锁定 -->
              <span  v-if="row.name==$t('AppManage.ThreatDefinition.RiskIP.ThreatIP')">{{$t('AppManage.ThreatDefinition.RiskIP.Desc3')}}{{ row.content.timeRange}}{{$t('AppManage.ThreatDefinition.RiskIP.Desc4')}}{{ row.content.number }}{{$t('AppManage.ThreatDefinition.RiskIP.Desc6')}}</span>
              <!-- 同IP( )小时内发生( )次违规风险后，判定为风险IP并锁定 -->
              <span  v-if="row.name==$t('AppManage.ThreatDefinition.RiskIP.OffendingIP')">{{$t('AppManage.ThreatDefinition.RiskIP.Desc3')}}{{ row.content.timeRange}}{{$t('AppManage.ThreatDefinition.RiskIP.Desc4')}}{{ row.content.number }}{{$t('AppManage.ThreatDefinition.RiskIP.Desc5')}}</span>
            </template>
          </el-table-column>
          <!-- 停用启用 -->
          <el-table-column prop="enable" :label="$t('AppManage.ThreatDefinition.RiskIP.Enable')" width="100" :show-overflow-tooltip="true"> 
              <!-- <template  v-slot="{row}">
                  <el-switch v-model="enable"  @change="changeSwitch(a,row)" active-value="1" inactive-value="0"></el-switch>
              </template> -->
              <template  v-slot="{row}">
                <el-switch v-model="row.enable" :active-value="1" :inactive-value="0"
                  @change="changeSwitch(row)">
                </el-switch>
              </template>
          </el-table-column>
          <el-table-column prop="updateTime" :label="$t('public.ModificationTime')"  sortable="custom"  width="180"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                  <img @click="edit(scope.row)" src="@/assets/icon_write.png" style="width: 15px; height: 15px; cursor: pointer" :title="$t('public.Editor')" />
              </span>
            </template>
          </el-table-column>
      </el-table>
      <!-- 爆破IP弹框 -->
      <Eldialog class="dialog" @handleClose="handleCloseBlasting"  :title="title" :flagbtn="flagbtn" :btnTitle="btnTitle" :determineBtn="determineBtn" :cancelBtn="cancelBtn" @determine="determineBlasting" :dialogVisible="blastingVisible" v-if="blastingVisible">
        <div class="box-corn">
          <el-form  ref="form" :model="form" :rules="rules"  label-width="140px" class="demo-ruleForm">
              <!-- <el-form-item :label="labelStr" prop="number" v-show="title=='爆破IP配置'" class="formItem">
                <el-input v-model="form.number" size="mini"  placeholder="1~9999" clear="input"></el-input><span style="margin-left:5px;display:inline-block;width:50px">次</span>
              </el-form-item> -->
               <!-- 威胁IP弹框 -->
              <el-form-item class="formItems" :label="$t('public.TimeFrame')+':'" prop="timeRange"  v-if="title==$t('AppManage.ThreatDefinition.RiskIP.ThreatIPConfiguration') || title==$t('AppManage.ThreatDefinition.RiskIP.OffendingIPConfiguration')">
                <el-input v-model="form.timeRange"  size="mini"  maxlength="4"   placeholder="1~9999"  clear="input"></el-input><span style="margin-left:5px;display:inline-block;width:50px">{{$t('AppManage.ThreatDefinition.RiskIP.Hours')}}</span>
            </el-form-item>
       
            <el-form-item    :class="title==$t('AppManage.ThreatDefinition.RiskIP.BlastingIPConfiguration')?'formItem':'formItems'"  :label="labelStr" prop="number" v-if=" title==$t('AppManage.ThreatDefinition.RiskIP.BlastingIPConfiguration') || title==$t('AppManage.ThreatDefinition.RiskIP.ThreatIPConfiguration') || title==$t('AppManage.ThreatDefinition.RiskIP.OffendingIPConfiguration')">
                <el-input v-model="form.number"  size="mini"  maxlength="4"   placeholder="1~9999"  clear="input"></el-input><span style="margin-left:5px;display:inline-block;width:50px">{{$t('AppManage.ThreatDefinition.RiskIP.Times')}}</span>
            </el-form-item>
            <!-- 违规IP弹框 -->
              <!-- <el-form-item label="时间范围：" prop="timeRange" v-if="title=='违规IP配置'">
                <el-input v-model="form.timeRange"  size="mini"   placeholder="1~9999" ></el-input><span  style="margin-left:5px;display:inline-block;width:50px">小时</span>
            </el-form-item>
            <el-form-item label="违规次数：" prop="number" v-if="title=='违规IP配置'">
                <el-input v-model="form.number"  size="mini"   placeholder="1~9999" ></el-input><span  style="margin-left:5px;display:inline-block;width:50px">次</span>
            </el-form-item> -->
            <!-- <el-form-item   class="footertrans">
              
            </el-form-item> -->
          </el-form>
        </div>
        <div v-if="flagbtn" slot="footer" class="diailog-footer">
          <el-button v-if="determineBtn"  size="mini" type="primary" class="btn-determine" @click="determineBlasting">{{ btnTitle }}</el-button>
          <el-button v-if="cancelBtn" size="mini"  class="btn-cancel cancelBtn" @click="cancel">{{$t('public.Cancel')}}</el-button>
        </div>
      </Eldialog>
      <!-- 分页 -->
      <pagination style="margin-left: 10px; margin-right: 10px" :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
  </div>
</template>
<script>
import Eldialog from '@/components/elDialog' //弹框
import pagination from '@/components/pagination/page'
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { pagination,Eldialog,dialogInfo, },
  data() {
    var patt = /^\+?[1-9]{1}[0-9]{0,3}\d{0,0}$/;
    var validateTime = (rule, value, callback) => {

        if (value === '') {
          callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.Placeholder')));
        } else {
          if (!patt.test(this.form.timeRange)) {
            callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.NumberPlaceholder')));
          }else {
            callback();
          }
        }
    };
    var validateNum = (rule, value, callback) => {
      // 威胁次数
      if(this.labelStr === this.$t('AppManage.ThreatDefinition.RiskIP.ThreatNumber')+':'){
        if (value === '') {
          callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.ThreatPlaceholder')));
        } else {
          if (!patt.test(this.form.number)) {
            callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.NumberPlaceholder')));
          }else {
            callback();
          }
        }
        // 爆破次数
      }else if(this.labelStr === this.$t('AppManage.ThreatDefinition.RiskIP.BlastingNumber')+':'){
        if (value === '') {
          callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.BlastingNumber')));
        } else {
          if (!patt.test(this.form.number)) {
            callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.NumberPlaceholder')));
          }else {
            callback();
          }
        }
        // 违规次数
      }else if(this.labelStr === this.$t('AppManage.ThreatDefinition.RiskIP.OffendingNumber')+':'){
        if (value === '') {
          callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.OffendingPlaceholder')));
        } else {
          if (!patt.test(this.form.number)) {
            callback(new Error(this.$t('AppManage.ThreatDefinition.RiskIP.NumberPlaceholder')));
          }else {
            callback();
          }
        }
      }

    };
    return {
      incurs:{
        id:'',
        name:'',
        content:{
          timeRange:"",
          number:""
        }
      },
      labelStr:this.$t('AppManage.ThreatDefinition.RiskIP.BlastingNumber')+':',
      title:this.$t('AppManage.ThreatDefinition.RiskIP.BlastingIPConfiguration'),//弹框title'爆破IP配置'
      btnTitle:this.$t('public.Save'),//'保存'
      flagbtn: true,
      cancelBtn: true,
      determineBtn: true,
      blastingVisible:false,//爆破IP弹框默认不展示
      threatVisible:false,//威胁IP弹框默认不展示
      violatVisible:false,//违规IP弹框默认不展示
      enable: true,//停用启用开关默认
      orderColume: "updateTime",
      orderRule: "DESC",
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,
      pageSizes: [10, 20, 30, 40],
      delarr: [],
      tableData: [],
      multipleSelection: [],

      form: {
        number:'',
        timeRange:'',
      },
      rules: {
        number: [ 
          // { required: true, trigger: 'blur', message:'请输入爆破次数'  },
          { validator: validateNum, trigger: 'blur',required: true }
        ],
        timeRange: [ 
          { required: true, trigger: 'blur', message: this.$t('AppManage.ThreatDefinition.RiskIP.Placeholder') },
          { validator: validateTime, trigger: 'blur' }
        ],
      },

    };
  },
  watch: {},
  created() {},

  mounted() {
    this.getDataList()
  },

  methods: {
    getRowKeys(row) {
      return row.id
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.getDataList()
    },
    //取消按钮
    cancel(){
      this.violatVisible = false //弹框关闭
      this.threatVisible = false //弹框关闭
      this.blastingVisible = false //弹框关闭
    },
    //爆破IP弹框
    handleCloseBlasting(){
      this.blastingVisible = false //弹框关闭
    },
    //监听排序
    handleSortChange(column) {
      if (column.order === 'ascending') {
        this.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.orderRule = 'DESC'
      }
      this.orderColume = column.prop
      this.getDataList()
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule
      }
      const res = await this.$axios.post(
        '/httpServe/monitorExDangerIp/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
      this.total = res.data.total
      console.log(this.tableData[0].content.number,".number")
    },
    //启用/禁用风险IP配置
    async  changeSwitch (row) {
      console.log(row,'row-0--启用/禁用----')
      var params = {
        id:row.id,
        // name:row.name,
        enable:row.enable,		//0-禁用 1-启用
      };
      const res =  await  this.$axios.post(
        "/httpServe/monitorExDangerIp/enable",
        params,
        true
      );
      if(res.data==1){
        this.getDataList();
      }
    },

    // 编辑
    edit(info) {
      console.log(info.name,info.id,"info")
      console.log(info," 编辑")
      this.incurs.id=info.id
      if(info.name==this.$t('AppManage.ThreatDefinition.RiskIP.BlastingIP')){
        this.title=this.$t('AppManage.ThreatDefinition.RiskIP.BlastingIPConfiguration')
        this.name=this.$t('AppManage.ThreatDefinition.RiskIP.BlastingIP')
        this.labelStr=this.$t('AppManage.ThreatDefinition.RiskIP.BlastingNumber')+':'
        // 爆破IP
        this.blastingVisible = true //弹框打开
        //弹框回显
        this.form.number=info.content.number
        this.form.timeRange=''
      }else if(info.name==this.$t('AppManage.ThreatDefinition.RiskIP.ThreatIP')){
        this.title=this.$t('AppManage.ThreatDefinition.RiskIP.ThreatIPConfiguration')
        this.name=this.$t('AppManage.ThreatDefinition.RiskIP.ThreatIP')
        this.labelStr=this.$t('AppManage.ThreatDefinition.RiskIP.ThreatNumber')+':'
        // 威胁IP
        this.blastingVisible = true //弹框打开
        //弹框回显
        this.form.number=info.content.number
        this.form.timeRange=info.content.timeRange
      }else if(info.name==this.$t('AppManage.ThreatDefinition.RiskIP.OffendingIP')){
        this.title=this.$t('AppManage.ThreatDefinition.RiskIP.OffendingIPConfiguration')
        this.name=this.$t('AppManage.ThreatDefinition.RiskIP.OffendingIP')
        this.labelStr=this.$t('AppManage.ThreatDefinition.RiskIP.OffendingNumber')+':'
        // 违规IP
        this.blastingVisible = true //弹框打开
        //弹框回显
        this.form.number=info.content.number
        this.form.timeRange=info.content.timeRange
      }
    },
    //爆破IP弹框保存按钮
    determineBlasting(){
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         this.saveBtn()
        } else {
          this.blastingVisible = true //弹框关闭
          console.log('error submit!!')
          return false
        }
      })
     
    },
    //编辑后保存
    async saveBtn(){
      console.log(this.form,"this.form")
      var params = {
        id:this.incurs.id,
        name:this.name,
        content:{
          timeRange:this.form.timeRange,
          number:this.form.number
        }
      };
        const res =  await  this.$axios.post(
          "/httpServe/monitorExDangerIp/update",
          params,
          true
        );
        console.log(res,'jjjjjjjjjjjjjjjjjjjjjjj')
        if(res.data==1){
          this.getDataList();
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: 'success',
            offset: 100
          })
          this.blastingVisible = false //弹框关闭
        }
    },
   
  }
}
</script>

<style lang="scss" scoped>

/**
  * {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
.el-form {
  margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

**/

.input{
  width: 200px;
}

.el-table__body {
  overflow: auto;
}


.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}
::v-deep .el-dialog{
  width: 500px !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item__content {
  display: flex !important;
}
::v-deep .formItems{
  width: 250px;
  // margin-top: 20px !important;
  // margin-bottom: 25px !important;
}
::v-deep .formItem{
  width: 250px;
  // margin-top: 20px !important;
  // margin-bottom: 88px !important;
}
::v-deep .footertrans{
  margin-left: 185px  !important;
/**  margin-top: 80px !important;   **/
}

::v-deep .box-corn{
  margin-top: 10px !important;
}
::v-deep .footertrans .cancelBtn{
  margin-left: 19px !important;
}
::v-deep #detailBox .el-dialog__body {
  padding: 5px 12px 0px 20px !important;
  /* height: 469px !important; */
  overflow: auto !important;
  margin-right: 12px !important;
}
</style>