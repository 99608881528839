<template>
  <div class="httpsjc">
    <div v-if="flag == 1">
      <el-row :gutter="3" style="padding: 0 0 0 10px">
        <el-col :span="3">
          <el-input
            v-model.trim="name"
            :placeholder="$t('AppManage.ThreatDefinition.HTTPSHijack.Placeholder')"
            @keyup.enter.native="search"
            size="small"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="search" size="small">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <div class="btn">
        <el-button-group>
          <el-button type="primary" @click="addNew" size="small">{{
            $t("public.New")
          }}</el-button>
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delArr(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </div>
      <div style="position: relative">
        <el-table
          ref="tableData"
          :data="tableData"
          :row-key="getRowKeys"
          lazy
          size="mini"
          stripe
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%; margin-left: 10px; padding-right: 20px"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          @selection-change="delobj"
          @sort-change="handleSortChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('AppManage.ThreatDefinition.HTTPSHijack.Name')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="content.subject"
            :label="$t('AppManage.ThreatDefinition.HTTPSHijack.Subject')"
            width="300"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="content.modulus"
            :label="$t('AppManage.ThreatDefinition.HTTPSHijack.Modulus')"
            width="300"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="content.validity"
            :label="$t('AppManage.ThreatDefinition.HTTPSHijack.Validity')"
            width="300"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="updateTime"
            :label="$t('AppManage.ThreatDefinition.HTTPSHijack.UpdateTime')"
            width="180"
            sortable="custom"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <span class="action_icon">
                <img
                  @click="del(scope.row.id)"
                  src="@/assets/icon_del.png"
                  style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                  :title="$t('public.Delete')"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          style="margin-left: 10px; margin-right: 10px"
          :page="currentPage4"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </div>
    <!-- 新增 -->
    <div v-else>
      <el-upload
        style="margin-left: 200px"
        ref="form"
        accept=".cer"
        :data="form"
        :limit="1"
        :on-exceed="handleExceed"
        action="'/httpServe/monitorExHttpsRule/save'"
        :auto-upload="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-change="uploadChange"
        drag
        :multiple="true"
        class="el-upload1"
      >
        <div class="left">
          <el-button size="small" type="primary">{{
            $t("AppManage.ThreatDefinition.SelectFile")
          }}</el-button>
          <div slot="tip" class="el-upload__tip">
            {{ $t("AppManage.ThreatDefinition.Support") }}
          </div>
        </div>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t("AppManage.ThreatDefinition.Drag")
          }}{{ $t("AppManage.ThreatDefinition.Or")
          }}<em>{{ $t("AppManage.ThreatDefinition.Upload") }}</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          {{ $t("AppManage.ThreatDefinition.HTTPSHijack.SupportCer") }}
        </div>
      </el-upload>
      <el-progress style="margin-left: 200px;width: 360px" v-if="flagStatus" :percentage="percentage" :color="customColorMethod"></el-progress>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="upload('form')" size="mini" class="save">{{
          $t("public.Save")
        }}</el-button>
      </div>
    </div>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { pagination, dialogInfo },

  data() {
    return {
      flagStatus: false,
      percentage: 0,
      typeInfo: "info", // 删除按钮默认灰色
      Sencond: 5, //设置初始倒计时
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      flag: 1,
      saveFlag: 0,
      seccess: "",
      id: "",
      name: "",
      dataFlag: "",
      orderColume: "updateTime",
      orderRule: "DESC",
      Successdialog: false, //控制弹出
      isDisabled: false,
      time: null,
      fileList: [],
      delarr: [],
      tableData: [],
      multipleSelection: [],

      form: {
        name: "",
        content: {
          cerName: "", //证书名称
        },
      },
    };
  },

  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.getDataList();
  },

  methods: {
    getRowKeys(row) {
      return row.id;
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    // 文件限制
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("AppManage.ThreatDefinition.SelectOne"));
    },
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    //监听排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.search();
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        type: "https-ca",
        manage: 1,
        name: this.name,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      const res = await this.$axios.post(
        "/httpServe/monitorExHttpsRule/getDataInfo",
        param,
        true
      );
      console.log(res);
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 查询
    async search() {
      if (this.name.length > 0) {
        this.currentPage4 = 1;
      }
      this.getDataList();
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var param = { ids: ids };
      this.$confirm(
        this.$t("AppManage.ThreatDefinition.HTTPSHijack.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          // 请求接口
          const res = await this.$axios.post(
            "/httpServe/monitorExHttpsRule/delete",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage4 = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    del(id) {
      this.$confirm(
        this.$t("AppManage.ThreatDefinition.HTTPSHijack.SureToDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          // 请求接口
          var parma = { ids: [id] };
          const res = await this.$axios.post(
            "/httpServe/monitorExHttpsRule/delete",
            parma,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage4 = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 新增
    addNew() {
      this.flag = 2;
      this.dataFlag = "";
    },
    //  新增 上传
    async uploadChange(file, fileList) {
      this.percentage = 0;
      this.flagStatus = true;
      this.$message({
        message: this.$t("AppManage.ThreatDefinition.Uploading"),
        type: "success",
        offset: 100,
        duration: 2000,
      });
      this.saveFlag = 3;
      // 获取上传的文件
      var files = file;
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (files.name.substr(files.name.length - 3) === "cer") {
        let formData = new FormData();
        formData.append("file", file.raw);
        formData.append("fileId", "file");
        const res = await this.$axios.upload(
          "/httpServe/monitorExHttpsRule/save",
          formData,
          this.updateProgress,
          false
        );
        this.dataFlag = res.data.data;
        this.saveFlag = 2;
        if (res.data.data == 1) {
          this.saveFlag = 1;
          this.$message({
            message: this.$t("AppManage.ThreatDefinition.UploadSuccess"),
            type: "success",
            offset: 100,
          });
          this.flagStatus = false;
        } else if (res.data.data == -1) {
          this.saveFlag = 2;
          this.$message({
            message: this.$t("AppManage.ThreatDefinition.HTTPSHijack.SameName"),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
          this.$refs.form.clearFiles();
        } else {
          this.saveFlag = 2;
          this.$message({
            message: this.$t("AppManage.ThreatDefinition.Error"),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
          this.$refs.form.clearFiles();
        }
      } else {
        this.saveFlag = 2;
        this.$message({
          type: "error",
          message: this.$t("AppManage.ThreatDefinition.HTTPSHijack.RequiredFile"),
        });
        this.flagStatus = false;
        this.$refs.form.clearFiles();
        return false;
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    upload() {
      if (this.saveFlag == 1 && this.dataFlag == 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.flag = 1;
        this.getDataList();
      } else if (this.dataFlag == "" && this.saveFlag == 2) {
        this.$message({
          message: this.$t("AppManage.ThreatDefinition.HTTPSHijack.UploadFirst"),
          type: "error",
          offset: 100,
        });
      } else if (this.saveFlag == 3) {
        this.$message({
          message: this.$t("AppManage.ThreatDefinition.Uploading"),
          type: "success",
          offset: 100,
          duration: 2000,
        });
      }
    },
    beforeUpload(file) {
      let isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message.error(this.$t("AppManage.ThreatDefinition.SizeLimit"));
      }
      this.filename = file.name;
      return isLt2M;
    },
    handleSuccess(response, file, fileList) {
      this.$refs.form.clearFiles();
      this.$notify({
        title: this.$t("AppManage.ThreatDefinition.UploadSuccess"),
        type: "success",
        duration: 2500,
      });
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: "error",
        duration: 2500,
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

.zrgj {
  width: 100%;
  height: 100%;
}

.el-button + .el-button {
  margin-left: 0px;
}

// 处理按钮
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 1px;
}

.save {
  margin: 14px 0 0 200px;
}

.el-form {
  margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

.addbox {
  display: flex;
  padding-left: 110px;
  padding-top: 30px;
}

//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;

  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}

.left {
  top: 200px;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}

::v-deep .el-upload-dragger .el-upload__text em {
  font-size: 14px;
}
</style>