<template>
  <div class="fxyy">
    <div v-if="flag == 1">
      <el-row :gutter="3" style="padding: 0 0 0 10px">
        <el-col :span="3">
      <el-input v-model.trim="name" :placeholder="$t('AppManage.ThreatDefinition.CloudPhone.Placeholder')" size="small" @keyup.enter.native="search">
      </el-input>
    </el-col>
        <el-col :span="4">
      <el-button type="primary" size="small" @click="search">{{$t('public.Inquire')}}</el-button>
    </el-col>
      </el-row>
      <div class="btn">
        <el-button-group>
        <el-button type="primary" @click="addNew" size="small">{{$t('public.New')}}</el-button>
        <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delArr(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">{{$t('public.Delete')}}</el-button>
        </el-button-group>
      </div>
      <div style="position: relative">
        <el-table ref="tableData" :data="tableData" :row-key="getRowKeys" lazy size="mini" stripe class="tableClass"
          tooltip-effect="dark" style="width: 100%; margin-left: 10px;padding-right:20px" :row-style="iRowStyle" :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle" :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }" :default-sort="{ prop: 'updateTime', order: 'descending' }" @selection-change="delobj"
          @sort-change="handleSortChange">
          <el-table-column type="selection" align="center" width="60"> </el-table-column>
          <el-table-column prop="name" :label="$t('public.ApplicationName')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="os" :label="$t('public.SystemCategory')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="content.packageName" :label="$t('public.ApplicationID')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" width="180" sortable="custom" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template slot-scope="scope">
              <img @click="edit(scope.row)" src="@/assets/icon_write.png"
                style="width: 15px; height: 15px; cursor: pointer" :title="$t('public.Editor')" />

              <img @click="del(scope.row.id)" src="@/assets/icon_del.png" style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                " :title="$t('public.Delete')" />
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination style="margin-left: 10px; margin-right: 10px" :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"></pagination>
      </div>
    </div>
    <!-- 新增 -->
    <div v-else-if="flag == 2" style="margin-top: 5px">
      <el-form :model="form" :rules="rules" ref="form" label-width="180px" class="demo-ruleForm">
        <el-form-item :label="$t('public.ApplicationName')+':'" prop="name" style="margin-bottom: 20px">
          <el-input type="text" v-model.trim="form.name" clearable size="small" :placeholder="$t('AppManage.ThreatDefinition.CloudPhone.Placeholder')" class="el-input1">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('public.ApplicationID')+':'" prop="content.packageName">
          <el-input type="text" v-model.trim="form.content.packageName" clearable size="small" :placeholder="$t('AppManage.ThreatDefinition.CloudPhone.AppPlaceholder')"
            class="el-input1"></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.SystemCategory')+':'" prop="os">
          <!-- <el-radio-group v-model="form.os"> -->
          <el-radio v-model="form.os" label="Android">Android</el-radio>
          <!-- <el-radio v-model="form.os.iOS" label="iOS">iOS</el-radio> -->
          <!-- </el-radio-group> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add('form')" size="mini" class="save">{{$t('public.Save')}}</el-button>
      </div>
    </div>
    <!-- 编辑 -->
    <div v-else style="margin-top: 5px">
      <el-form :model="form" :rules="rules" ref="form" label-width="180px" class="demo-ruleForm">
        <el-form-item :label="$t('public.ApplicationName')+':'" prop="name">
          <el-input type="text" v-model.trim="form.name" clearable size="small" :placeholder="$t('AppManage.ThreatDefinition.CloudPhone.Placeholder')" class="el-input1">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('public.ApplicationID')+':'" prop="content.packageName">
          <el-input type="text" v-model.trim="form.content.packageName" clearable size="small" :placeholder="$t('AppManage.ThreatDefinition.CloudPhone.AppPlaceholder')"
            class="el-input1"></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.SystemCategory')+':'" prop="os">
          <!-- <el-radio-group v-model="form.os"> -->
          <el-radio v-model="form.os" label="Android">Android</el-radio>
          <!-- <el-radio v-model="form.os" label="iOS">iOS</el-radio> -->
          <!-- </el-radio-group> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('form')" size="mini" class="save">{{$t('public.Save')}}</el-button>
      </div>
    </div>
    <!-- 保存成功的弹框 -->
    <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess"
      :width="'240px'" />
  </div>
</template>
<script>
import pagination from "@/components/pagination/page";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { pagination, dialogInfo },

  data() {
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      tableData: [],
      multipleSelection: [],
      //   分页
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      pageSizes: [10, 20, 30, 40],
      flag: 1,
      seccess: "",
      id: "",
      name: "",
      rules: {
        name: [
          { required: true, trigger: "blur", message: this.$t('AppManage.ThreatDefinition.CloudPhone.Placeholder') },
          { min: 1, max: 30, message: this.$t('AppManage.ThreatDefinition.NameLength'), trigger: "blur" },
        ],
        content: {
          packageName: [
            { required: true, message: this.$t('AppManage.ThreatDefinition.CloudPhone.AppPlaceholder'), trigger: "blur" },
            { min: 1, max: 30, message: this.$t('AppManage.ThreatDefinition.NameLength'), trigger: "blur" },
          ],
        },
      },
      form: {
        name: "",
        os: "Android", //系统类别, iOS, Android
        content: {
          packageName: "", //风险特征
        },
      },
      Successdialog: false, //控制弹出
      delarr: [],
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      orderColume: "updateTime",
      orderRule: "DESC",
    };
  },

  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  watch: {},
  created() { },

  mounted() {
    this.getDataList();
  },

  methods: {
    getRowKeys(row) {
      return row.id
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    //监听排序
    handleSortChange(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.search();
    },
    // 列表
    async getDataList() {
      var param = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        type: "cloud_phone",
        manage: 1,
        name: this.name,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      const res = await this.$axios.post(
        "/httpServe/monitorExCloudPhone/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查询
    async search() {
      if (this.name.length > 0) {
        this.currentPage = 1;
      }
      this.getDataList();
    },
    addNew() {
      this.flag = 2;
    },
    // 新增
    async add(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var param = {
            name: this.form.name, //名称
            level: "0",
            type: "cloud_phone",
            os: this.form.os, //系统类别
            content: {
              packageName: this.form.content.packageName, //文件名称
            },
          };
          const res = await this.$axios.post(
            "httpServe/monitorExDangerRule/save",
            param,
            true
          );
          if (res.data == -1) {
            this.$message({
              message: this.$t('AppManage.ThreatDefinition.CloudPhone.SameName'),
              type: 'error',
              offset: 100
            });
          } else {
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: 'success',
              offset: 100
            });
            this.flag = 1;
            this.name = "";
            this.getDataList();
            this.form = {
              name: "",
              os: "Android", //系统类别, iOS, Android
              content: {
                packageName: "", //风险特征
              },
            };
          }
        } else {
          console.log("error");
          return false;
        }
      });
    },
    // 回显
    async edit(info) {
      this.flag = 3;
      var parma = {
        id: info.id,
      };
      const res = await this.$axios.post(
        "/httpServe/monitorExFrameRule/selectOne",
        parma,
        true
      );
      console.log(res);
      this.id = res.data.id;
      this.form.name = res.data.name;
      this.form.content.packageName = res.data.content.packageName;
    },
    // 编辑后保存
    save(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var parma = {
            id: this.id, //ID (新增时无)
            name: this.form.name, //名称
            level: "0",
            type: "frame",
            os: this.form.os, //系统类别
            content: {
              packageName: this.form.content.packageName, //文件名称
            },
          };
          const res = await this.$axios.post(
            "/httpServe/monitorExFrameRule/update",
            parma,
            true
          );
          if (res.errorCode == "786437") {
            this.$message({
              message: this.$t('AppManage.ThreatDefinition.CloudPhone.SameCategory'),
              type: 'error',
              offset: 100
            })
            return false
          } else {
            // this.Successdialog = true;
            // this.seccess = "保存成功";
            this.$message({
              message: this.$t('public.SuccessfullySaved'),
              type: 'success',
              offset: 100
            });
            this.flag = 1;
            this.name = "";
            this.getDataList();
            this.form = {
              name: "",
              os: "Android", //系统类别, iOS, Android
              content: {
                packageName: "", //风险特征
              },
            };
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 批量删除
    delobj(obj) {
      this.delarr = obj;
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var param = { ids: ids };
      this.$confirm(this.$t('AppManage.ThreatDefinition.CloudPhone.SureToDel'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          // 请求接口
          const res = await this.$axios.post(
            "/httpServe/monitorExDangerRule/delete",
            param,
            true
          );
          // this.Successdialog = true;
          // this.seccess = "删除成功";
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          });
          this.$refs.tableData.clearSelection();
          this.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    del(id) {
      this.$confirm(this.$t('AppManage.ThreatDefinition.CloudPhone.SureToDel'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: "persdsd",
        type: "warning",
      })
        .then(async () => {
          // 请求接口
          var parma = { ids: [id] };
          const res = await this.$axios.post(
            "/httpServe/monitorExDangerRule/delete",
            parma,
            true
          );
          // this.Successdialog = true;
          // this.seccess = "删除成功";
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          });
          this.$refs.tableData.clearSelection();
          this.currentPage = 1;
          this.getDataList();
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

.zrgj {
  width: 100%;
  height: 100%;
}

.el-input1 {
  width: 250px;
}

.el-button+.el-button {
  margin-left: 0px;
}
// 处理按钮
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 1px;
}
.save {
  margin: 0px 0 0 180px;
}

.el-form {
  margin-top: 10px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;

  // background:rgba(255, 255, 255,.1)
  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-table__body {
  overflow: auto;
}

.el-form-item__content {
  width: 76%;
  display: flex;
  margin: 0 !important;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}
::v-deep .el-table__row>td { border: none; }
::v-deep .el-table::before { height: 0px; }
::v-deep .el-table th.is-leaf {border: none;}
</style>