<template>
  <div class="gpycxw">
    <div class="box" v-if="flag">
      <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" ref="tableData" :data="tableData" row-key="id" lazy size="mini" stripe class="tableClass" tooltip-effect="dark" style="width: 100%; margin-left: 10px;padding-right:20px" :default-sort="{ prop: 'updateTime', order: 'descending' }" @sort-change="handleSortChange">
        <el-table-column type="index" :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.OrderNumber')" width="55">
        </el-table-column>
        <el-table-column prop="name" :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Name')" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="content.unit" :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Unit')" width="180" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.content.time }}</span>
            <span v-if="scope.row.content.unit == 'h'">{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Hour')}}</span>
            <span v-else-if="scope.row.content.unit == 'm'">{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Minute')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content.frequency" :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Frequency')" width="180" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.content.frequency > 0">{{ scope.row.content.frequency }}{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Times')}}</span>
            <span v-else>0{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Times')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" :label="$t('public.ModificationTime')" width="180" sortable="custom" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column :label="$t('public.Controls')">
          <template slot-scope="scope">
            <span class="action_icon">
              <img @click="edit(scope.row)" src="@/assets/icon_write.png" style="width: 15px; height: 15px; cursor: pointer" :title="$t('public.Editor')" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination style="margin-left: 10px; margin-right: 10px" :page="currentPage4" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
    </div>
    <!-- 编辑 -->
    <div v-else style="margin-top: 5px">
      <el-form @submit.native.prevent :model="form" :rules="rules" ref="form" label-width="200px" class="demo-ruleForm">
        <el-form-item :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Name')+':'" prop="name">
          <el-select v-model="form.name" placeholder="请选择" size="small" disabled>
            <el-option v-for="item in form.name" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Unit')+':'" prop="content.time">
          <el-input type="text" v-model="form.content.time" clearable size="small" class="el-input" maxlength="4" @change="checkDefault1"></el-input>
          <el-select v-model="form.content.unit" class="select" size="small" @change="checkDefault1">
            <el-option v-for="item in options" :key="item.value" :label="$t(`AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.SelectOptions.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
          <el-checkbox v-model="checked1" @change="default1">{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.UseDefault')}}</el-checkbox>
        </el-form-item>
        <el-form-item :label="$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Frequency')+':'" prop="content.frequency">
          <el-input type="text" v-model="form.content.frequency" clearable size="small" class="el-input" maxlength="4" @change="checkDefault1"></el-input>
          <span style="font-size: 14px; margin-right: 112px">{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Times')}}</span>
          <el-checkbox v-model="checked2" @change="default2">{{$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.UseDefault')}}</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save('form')" size="mini" class="save">{{$t('public.Save')}}</el-button>
      </div>
    </div>
    <!-- 保存成功的弹框 -->
    <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="seccess" :width="'240px'" />
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { pagination, dialogInfo },

  data() {
    return {
      value: "1",
      id: "",
      seccess: "",
      orderColume: "updateTime",
      orderRule: "DESC",
      Successdialog: false, //控制弹出
      isDisabled: false,
      time: null,
      flag: true,
      checked1: false,
      checked2: false,
      Sencond: 5, //设置初始倒计时
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      tableData: [],
      multipleSelection: [],
      options: [
        {
          value: 'h',
          label: '小时'
        },
        {
          value: 'm',
          label: '分钟'
        }
      ],
      fileList: [
        {
          name: "",
          url: "",
        },
        {
          name: "",
          url: "",
        },
      ],
      rules: {
        content: {
          time: [
            { min: 1, max: 4, message: this.$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Placeholder'), trigger: 'blur' }
          ]
        },
        content: {
          frequency: [
            { min: 1, max: 4, message: this.$t('AppManage.ThreatDefinition.HighFrequencyAbnormalBehavior.Placeholder'), trigger: 'blur' }
          ]
        }
      },
      form: {
        name: '',
        content: { time: '', frequency: '', unit: '' },
        radio: 'Android'
      },
    };
  },

  watch: {},
  created() {},

  mounted() {
    this.getDataList()
  },

  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page
      this.pageSize = limit
      // 调用查询方法
      this.getDataList()
    },
    handleClose() {
      this.Successdialog = false //提示消息  关闭
    },
    determine() {
      this.Successdialog = false //提示消息  关闭
    },
    //监听排序
    handleSortChange(column) {
      if (column.order === 'ascending') {
        this.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.orderRule = 'DESC'
      }
      this.orderColume = column.prop
      this.getDataList()
    },
    // 列表
    async getDataList() {
      var param = {
        // pageNumber: this.currentPage4, //页数
        // rowNumber: this.pageSize, //行数
        // sort: "r.updateTime desc", //排序, desc-倒序， asc-正序, 支持字段：r.name(域名), r.updateTime(修改时间)
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        type: 'frequency',
        manage: 1,
        orderColume: this.orderColume,
        orderRule: this.orderRule
      }
      const res = await this.$axios.post(
        '/httpServe/monitorExFrequencyRule/getDataInfo',
        param,
        true
      )
      console.log(res)
      this.tableData = res.data.content
      this.total = res.data.total
    },
    // 验证是不是默认
    checkDefault1() {
      if (this.form.content.time == '12' && this.form.content.unit == 'h') {
        this.checked1 = true
      } else {
        this.checked1 = false
      }
      if (this.form.content.frequency == '3') {
        this.checked2 = true
      } else {
        this.checked2 = false
      }
    },
    // 默认
    default1() {
      if (this.checked1 == true) {
        this.form.content.time = '12'
        this.form.content.unit = 'h'
      }
      if (this.form.content.time == '12' && this.form.content.unit == 'h') {
        this.checked1 = true
      } else {
        this.checked1 = false
      }
    },
    default2() {
      if (this.checked2 == true) {
        this.form.content.frequency = '3'
      }
      if (this.form.content.frequency == '3') {
        this.checked2 = true
      } else {
        this.checked2 = false
      }
    },
    // 回显
    async edit(info) {
      this.flag = !this.flag
      var parma = {
        id: info.id
      }
      const res = await this.$axios.post(
        '/httpServe/monitorExFrequencyRule/selectOne',
        parma,
        true
      )
      console.log(res)
      this.id = res.data.id
      this.form.name = res.data.name
      this.form.content.time = res.data.content.time
      this.form.content.unit = res.data.content.unit
      this.options.value = this.form.content.unit
      this.form.content.frequency = res.data.content.frequency
      if (this.form.content.time == '12' && this.form.content.unit == 'h') {
        this.checked1 = true
      } else {
        this.checked1 = false
      }
      if (this.form.content.frequency == '3') {
        this.checked2 = true
      } else {
        this.checked2 = false
      }
    },
    // 编辑后保存
    save(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var param = {
            // id: this.id, //ID (新增时无)
            // name: this.form.name, //名称
            // content: {
            // unit: this.form.content.unit,
            // time: this.form.content.time,
            // frequency: this.form.content.frequency,
            // },
            id: this.id, //ID (新增时无)
            name: this.form.name, //域名
            level: '0',
            type: 'frequency', //类别
            os: 'All', //操作系统
            content: {
              unit: this.form.content.unit, //监测时间范围-单位，h-小时，m-分钟
              count: this.form.content.frequency, //行为出现次数
              time: this.form.content.time //监测时间范围-周期
            }
          }
          const res = await this.$axios.post(
            '/httpServe/monitorExFrequencyRule/update',
            param,
            true
          )
          // console.log(res);
          // this.Successdialog = true;
          // this.seccess = "保存成功";
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: 'success',
            offset: 100
          })
          this.flag = true
          this.getDataList()
        } else {
          console.log('error')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

.zrgj {
  width: 100%;
  height: 100%;
}

.el-input {
  width: 90px;
  margin-right: 10px;
}

.select {
  width: 105px;
  margin-right: 20px;
}

.el-input2 {
  width: 200px;
  margin-left: 10px;
}

.btn {
  margin-left: 10px;
  margin-right: 1px;
}

.el-button + .el-button {
  margin-left: 0px;
}

.btn {
  background: #4787de;
}

.center {
  margin: 10px 0;
  justify-content: flex-start;
}

.save {
  margin: 0px 0 0 180px;
}

.el-form {
  margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

.addbox {
  display: flex;
  padding-left: 110px;
  padding-top: 30px;
}

.el-form-item__content {
  width: 76% !important;
  display: flex;
  margin: 0 !important;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  height: 100px;
  overflow: auto;
  margin-right: 12px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>